import React from 'react';
import { graphql } from 'gatsby';
import Article from '../../components/template-parts/Article/Article';

export default ({ data }) => (
  <Article data={data} />
);

export const query = graphql`
    query post($id: String!, $nextPage: String, $previousPage: String) {
        page: wpPost(id: { eq: $id }) {
            title
            content
            date
            comments {
                nodes {
                    content
                    replies {
                        nodes {
                            content
                        }
                    }
                    id
                }
            }
            categories {
                nodes {
                    name
                }
            }
            author {
                node {
                    uri
                    name
                    firstName
                }
            }
            featuredImage {
                node {
                    localFile {
                        ...HeroImage
                    }
                }
            }
        }

        nextPage: wpPost(id: { eq: $nextPage }) {
            title
            uri
        }

        previousPage: wpPost(id: { eq: $previousPage }) {
            title
            uri
        }
    }
`;
